import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Software/Home_Automation/PrimaryBox';
import PushHostAlarm from 'components/Software/Home_Automation/CGI_Galleries/PushHostAlarm';
import SetInfrared from 'components/Software/Home_Automation/CGI_Galleries/SetInfrared';
import GoToPreset from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset';
import SetMdAttr from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr';
import SetMdAttr2 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr2';
import SetMdAttr3 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr3';
import GoToPreset2 from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset2';
import ManualRec from 'components/Software/Home_Automation/CGI_Galleries/ManualRec';
import GoToPreset3 from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset3';
import PtzCtrl from 'components/Software/Home_Automation/CGI_Galleries/PtzCtrl';
import PtzCtrlTour from 'components/Software/Home_Automation/CGI_Galleries/PtzCtrlTour';
import SetSchedule from 'components/Software/Home_Automation/CGI_Galleries/SetSchedule';
import SetScheduleBak from 'components/Software/Home_Automation/CGI_Galleries/SetScheduleBak';
import SetMdAlarm from 'components/Software/Home_Automation/CGI_Galleries/SetMdAlarm';
import SetMdAttr4 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr4';
import SetPirAttr from 'components/Software/Home_Automation/CGI_Galleries/SetPirAttr';
import SetIoAttr from 'components/Software/Home_Automation/CGI_Galleries/SetIoAttr';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/IFTTT/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IFTTT Cloud Automation",
  "path": "/Software/Home_Automation/IFTTT/",
  "dateChanged": "2019-10-23",
  "author": "Mike Polinowski",
  "excerpt": "If This Then That, also known as IFTTT is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera. Compatible with the camera models IN-8001 Full HD,  IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD",
  "social": "/images/Search/AU_SearchThumb_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_IFTTT_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "IFTTT"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='IFTTT Cloud Automation' dateChanged='2019-10-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='If This Then That, also known as IFTTT is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera. Compatible with the camera models IN-8001 Full HD,  IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_IFTTT.png' twitter='/images/Search/AU_SearchThumb_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Home_Automation/IFTTT/' locationFR='/fr/Home_Automation/IFTTT/' crumbLabel="IFTTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation",
        "aria-label": "home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation`}</h2>
    <h3 {...{
      "id": "ifttt-cloud-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ifttt-cloud-automation",
        "aria-label": "ifttt cloud automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IFTTT Cloud Automation`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#home-automation"
        }}>{`Home Automation`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ifttt-cloud-automation"
            }}>{`IFTTT Cloud Automation`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-does-it-work"
        }}>{`How does it work`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#externe-sensoren-verwenden"
            }}>{`Externe Sensoren verwenden`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nachtsicht-nur-aktivieren-wenn-bewegung-erkannt-wird"
            }}>{`Nachtsicht nur aktivieren, wenn Bewegung erkannt wird`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#verschiedene-positionen-je-nach-tageszeit"
            }}>{`Verschiedene Positionen je nach Tageszeit`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#verwenden-sie-f%C3%BCr-jede-position-unterschiedliche-erkennungsbereiche"
            }}>{`Verwenden Sie für jede Position unterschiedliche Erkennungsbereiche`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#verschiedene-empfindlichkeiten-f%C3%BCr-tag--nacht-verwenden"
            }}>{`Verschiedene Empfindlichkeiten für Tag & Nacht verwenden`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#schalten-sie-alle-kameras-aus-wenn-sie-nach-hause-kommen"
            }}>{`Schalten Sie alle Kameras aus, wenn Sie nach Hause kommen.`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#verwenden-sie-externe-sensoren-um-ihre-kamera-zu-drehen-wenn-bewegung-erkannt-wird"
            }}>{`Verwenden Sie externe Sensoren, um Ihre Kamera zu drehen, wenn Bewegung erkannt wird.`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#verwenden-sie-externe-sensoren-um-alarmaufzeichnungen-auszul%C3%B6sen"
            }}>{`Verwenden Sie externe Sensoren, um Alarmaufzeichnungen auszulösen.`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#verbinden-sie-ihre-kameras-miteinander"
            }}>{`Verbinden Sie Ihre Kameras miteinander`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nach-ger%C3%A4uschquellen-suchen"
            }}>{`Nach Geräuschquellen suchen`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#manually-disable-all-alarm-functions-for-all-your-cameras"
            }}>{`Manually disable all alarm functions for all your cameras.`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#umschalten-zwischen-pir-und-software-bewegungserkennung"
            }}>{`Umschalten zwischen PIR und Software Bewegungserkennung`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h2>
    <p><em parentName="p">{`If This Then That`}</em>{`, also known as IFTTT is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IFTTT_and_INSTAR_FHD_Cameras/"
      }}>{`use to take control over your camera`}</a>{` via the use of `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI commands`}</a>{`. And `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IFTTT_as_Alarmserver/"
      }}>{`trigger the IFTTT service when an alarm occurred on your camera`}</a>{`.`}</p>
    <p>{`Unlike `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/"
      }}>{`local Smarthome systems`}</a>{` you won't be able to directly connect wireless sensors and actors to the IFTTT service. Instead you always need a system that itself is supported by IFTTT to set up a connection to your camera through the IFTTT service. IFTTT offers a large variety of `}<a parentName="p" {...{
        "href": "https://ifttt.com/discover"
      }}>{`supported hardware and software systems`}</a>{` that fit this requirement - e.g. `}<a parentName="p" {...{
        "href": "https://ifttt.com/amazon_alexa"
      }}>{`Amazon Alexa`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ifttt.com/hue"
      }}>{`Philips Hue`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ifttt.com/google_assistant"
      }}>{`Google Assistant`}</a>{`, `}<a parentName="p" {...{
        "href": "https://ifttt.com/cortana"
      }}>{`Cortana`}</a>{` and general `}<a parentName="p" {...{
        "href": "https://ifttt.com/search/query/smarthome?tab=services"
      }}>{`Smarthome Systems`}</a>{`.`}</p>
    <p>{`The service requires a `}<strong parentName="p">{`HTTPS Connection`}</strong>{` and is thus only `}<strong parentName="p">{`compatible with Full HD camera models`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8001_HD/"
        }}>{`IN-8001 Full HD`}</a>{`,`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8003_HD/"
        }}>{`IN-8003 Full HD`}</a>{`, `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-8015_HD/"
        }}>{`IN-8015 Full HD`}</a>{`, `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9008_HD/"
        }}>{`IN-9008 Full HD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9010_HD/"
        }}>{`IN-9010 Full HD`}</a>{` `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-9020_HD/"
        }}>{`IN-9020 Full HD`}</a></li>
    </ul>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IFTTT_and_INSTAR_FHD_Cameras/"
      }}>{`detailed guide`}</a>{` will walk you through the steps of sending a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI command`}</a>{` to your camera to have it swivel between different positions depending on the time of day. Additionally, we offer a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IFTTT_as_Alarmserver/"
      }}>{`guide on how to use your camera's Alarmserver to trigger an IFTTT action`}</a>{` - we set up an applet that sends you a message on `}<a parentName="p" {...{
        "href": "https://ifttt.com/telegram"
      }}>{`Telegram`}</a>{` every time an alarm is triggered on your camera.`}</p>
    <p>{`If you are using a `}<strong parentName="p">{`VGA or HD model`}</strong>{` you can still use IFTTT but will have to use a tool like e.g. `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_IFTTT/"
      }}>{`Node-RED`}</a>{` to make the HTTPS connection to the IFTTT service.`}</p>
    <h2 {...{
      "id": "how-does-it-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-does-it-work",
        "aria-label": "how does it work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does it work`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "1-externe-sensoren-verwenden",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-externe-sensoren-verwenden",
        "aria-label": "1 externe sensoren verwenden permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Externe Sensoren verwenden`}</h3>
    <p>{`Auslösen eines Alarms auf der Kamera durch ein Ereignis, das von Ihrem Hausautomationssystem registriert wird:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/29007/IFTTT-EN_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDklEQVQoz23P2W7aQBTGcb9cH6ZP0qjqfa/bi95EqtRI6XIRpWobsiglLCGQAsVgG7ABr9ieGS//CrMoijrST2c0OuebGa0sSzY2K00FUspqT1mSFwUqzwk8F3Myxg9Cil3vZkVRhG3beJ6HUqo606rZXYOz8HHciBBwFXjA9djm5PQz519O+d7qMU1yDDfCDGJEAa7rI6Q6XKJlwYRKOCF1R6TLIdIdVTJPx5t1GfXrmHqbpdVl7fzBtR7wZ4+owCBZDVH+mH2OJuZ37Ml5HbXqI10T5c8QS51i0cJzOkymHcpFE+k0yZw71OwSYdWQdpOnGZp0Wmy1q4MssijSNYWMyWKPYtlhbLYZGffkThNhN0hXA/LOW1T9CLHsVaH7nEOg2NV4ckM4uCD6e0nY/0U+r/MwbmNNO2R2A4Ietz8+0fv4EtpHpItngZsbxXyrer55jRieb5lX1Yv0fpOLn98JjFtYtTg7ec/XNy/g/hWJ3dl9t1H1arFVo2LWSGY3xI1j4ssPW3fHJPNrHGtAt9cjMG5AP+f3t3c8nr2mHB2ztq5I9hlWDU0VIQf5tqZljChjcmJEFrJaLQjDgFSGlMTMHJ0kW5MhDjN7msx89lRVPaxEskgiDKOLOR1gzYbMHZ3pfIhh9UmlS5b7SOXydH5Dy4uE51wpiTNBHHusY49EBKQiJEmDisrW/G9u4x8/7DVTh8nfIgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3fe8b2a1b6715094036ca75d3708fb6/e4706/IFTTT-EN_01.avif 230w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/d1af7/IFTTT-EN_01.avif 460w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/7f308/IFTTT-EN_01.avif 920w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/e1c99/IFTTT-EN_01.avif 1380w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/2babf/IFTTT-EN_01.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b3fe8b2a1b6715094036ca75d3708fb6/a0b58/IFTTT-EN_01.webp 230w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/bc10c/IFTTT-EN_01.webp 460w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/966d8/IFTTT-EN_01.webp 920w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/445df/IFTTT-EN_01.webp 1380w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/fad48/IFTTT-EN_01.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b3fe8b2a1b6715094036ca75d3708fb6/81c8e/IFTTT-EN_01.png 230w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/08a84/IFTTT-EN_01.png 460w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/c0255/IFTTT-EN_01.png 920w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/b1001/IFTTT-EN_01.png 1380w", "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/29007/IFTTT-EN_01.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b3fe8b2a1b6715094036ca75d3708fb6/c0255/IFTTT-EN_01.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=pushhostalarm&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <PushHostAlarm mdxType="PushHostAlarm" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "2-nachtsicht-nur-aktivieren-wenn-bewegung-erkannt-wird",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-nachtsicht-nur-aktivieren-wenn-bewegung-erkannt-wird",
        "aria-label": "2 nachtsicht nur aktivieren wenn bewegung erkannt wird permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Nachtsicht nur aktivieren, wenn Bewegung erkannt wird`}</h3>
    <p>{`Aktivieren Sie die Nachtsicht Ihrer Kameras nach einem Zeitplan oder ausgelöst durch einen externen Sensor, der in die Haustechnik integriert ist:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b6787b5050e2285c78135c110ddd85f6/29007/IFTTT-EN_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACq0lEQVQoz22RXWxLYRyHz6XEjUsuRIjqaHfWc857ek57jq49W2nXmbJ2NUK2ySwTBBFbZ+FC4mO+tkRIpFz4iJD4CjKT6abMxwWyxA0XbnclBNlQ8lC2hHDx5H3z5s0vv///kablJ3CeF4m+KBJ+XsT16AvzRr4wZ+QrM/MfkW68ZfbQB1wjn/E9/UrVi28sflYkPvr7XDr6He3xONPvvEO69R5pxolhyk4XWJAr4MoV8JwpYJx9iPtUnvTAa+x7Y1QPvEE9/4RZJ4dYmLvP/FPDeHPDeHL3mXtiEOvqSwKDY7ivvkLyyx58Xg9KiXIParkHj9tF1AnzqFBgaGCQ/uvXWBGLIrtdqOVewlaAyqBJZcAkKFTy/bd5MlTgyvlzSE0t62lvbyexMsWi6lpENMHcQJj9h48x/rnIuQuXOdBzkO7du2ndsIG6VAO+RAozsYIyJ8G2ji4+fJrgZv8gHdlOJMMwMA0Dv99AMwPoQZtFisbGTVsoPHjKsb7jJJZGWZXJoCgKkUiEeDxGPBajsqqKdetbuTuQ5+KFS6iyF0kIwRS60NCFQFMUQqEQe/f3kIzX0LS8jkwmgyzL6LqOJgSqEBiaRq0m6M12s271GqoVH1LpwxRikqm7z72AZZbJ0d6DNDQ0UCHL+P1+LCFImibNus4On4+Yy0XUEHRlt/8d+CeqphEN2XR27+RQbw/pdBq5ogLTNEnGYqRDIZoti2bDwPHrdHTtoGtP9t/AqYalfS2pqWFnaz1H9u0i07iaMrebZXV19Pb1oSoK8WCQhGFgO1V0tjeya2vLfxqWdllqqKo4Tpi1SxSym5tIT0pJpVK0tbX9kmPbNromsBfbNNUGaFkZ+f/IYjK0JMnr9ZBMLqe+vv7Xu+M4BINBnEgEy7ImhWqoqoL6U8oPYyvE5DEk3RsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b6787b5050e2285c78135c110ddd85f6/e4706/IFTTT-EN_02.avif 230w", "/en/static/b6787b5050e2285c78135c110ddd85f6/d1af7/IFTTT-EN_02.avif 460w", "/en/static/b6787b5050e2285c78135c110ddd85f6/7f308/IFTTT-EN_02.avif 920w", "/en/static/b6787b5050e2285c78135c110ddd85f6/e1c99/IFTTT-EN_02.avif 1380w", "/en/static/b6787b5050e2285c78135c110ddd85f6/2babf/IFTTT-EN_02.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b6787b5050e2285c78135c110ddd85f6/a0b58/IFTTT-EN_02.webp 230w", "/en/static/b6787b5050e2285c78135c110ddd85f6/bc10c/IFTTT-EN_02.webp 460w", "/en/static/b6787b5050e2285c78135c110ddd85f6/966d8/IFTTT-EN_02.webp 920w", "/en/static/b6787b5050e2285c78135c110ddd85f6/445df/IFTTT-EN_02.webp 1380w", "/en/static/b6787b5050e2285c78135c110ddd85f6/fad48/IFTTT-EN_02.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b6787b5050e2285c78135c110ddd85f6/81c8e/IFTTT-EN_02.png 230w", "/en/static/b6787b5050e2285c78135c110ddd85f6/08a84/IFTTT-EN_02.png 460w", "/en/static/b6787b5050e2285c78135c110ddd85f6/c0255/IFTTT-EN_02.png 920w", "/en/static/b6787b5050e2285c78135c110ddd85f6/b1001/IFTTT-EN_02.png 1380w", "/en/static/b6787b5050e2285c78135c110ddd85f6/29007/IFTTT-EN_02.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b6787b5050e2285c78135c110ddd85f6/c0255/IFTTT-EN_02.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setinfrared&-infraredstat=auto&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setinfrared&-infraredstat=close&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetInfrared mdxType="SetInfrared" />
    <p>{`Die Aktivierung der Nachtsicht im Alarmfall ist natürlich nur möglich, wenn die Kamera über einen internen PIR-Sensor zur Bewegungserkennung verfügt, oder wenn Sie das Alarmsignal eines externen Sensors in der Heimautomation zum Auslösen der Kamera verwenden. Der Vorteil - die Kamera ist nachts unauffällig und das IR-Licht zieht keine Insekten / Spinnen an. Und natürlich wird auch der Stromverbrauch gesenkt.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "3-verschiedene-positionen-je-nach-tageszeit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3-verschiedene-positionen-je-nach-tageszeit",
        "aria-label": "3 verschiedene positionen je nach tageszeit permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Verschiedene Positionen je nach Tageszeit`}</h3>
    <p>{`Geplante Tag- und Nachtposition - bewachen Sie die Haustür, wenn ich nicht zu Hause bin und drehe die Kamera zm Carport, wenn ich von der Arbeit zurück bin:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/29007/IFTTT-EN_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC60lEQVQoz1WR708bBRjH7y/wjYlGEzPnkrmF64+7Xu/OAqEEGFEIGzCzFWItpaWCDuaYAhUZA9kSzTTGV8bEGWN8q69MtsSYbGMNwyJZ2MAVXHRbgrveAh2MtnfXj9nNF/oknzzvvk8+z1f4dKSRzPe7+SDVzrmJ9zjzfj8fpweZOpFksOs1Uh1h0v1xpt9t47PjT9EkexgdSJCKdPLm4YPEj3TQGg5R9cIzeF98HiHd08xk3MvQ6/WM9bSRTnQyHGniZKSB0eirDB8NMzMUZaS3nb7mp2mR93EkHORQjUKTItIk76c1JBPc/SzqS88h5K5nWV74ldziHL8vzLL02zWW5mdZzl7h2i8/MXvhB1YWM7zVE8O3dx91QQnp5T18ce4TTrzzNvv37KJOC9BYo9PW3IDAf6YCbO+U2NjaoQDcWMmRXVhk3TBJ9vUher345QCtbQfZKGxx6eocYk09h4920dXdTTTWg1AqFrGsMo5tUyg8JDM3z/LKLag42FbZPfTQNIlFo3i9XmRJoqWlhctXMly4+DPV1dVIfj+SLBPSNIRyuYTj2G6gbdvkVtf4+85d+DfsdjbLpe++IZFM4BFFdF1DVVUmTk1ybHAISZJQdR1/VRUd4VqEsmXjVHiC4zxxtyx35a5muPjl55ib94gnkoiiB/2VEKqmoakqfr8fJRhEEUUO1YaYODWKUNwusGOsUtpcdwNty8Iul3EqFdaycxjb62wUH9DXGyOs7OVArYSiKGiajq7ryIEAdZrKyNhJJqY/RNi6v4b1aJOd/G2s4jaOU3HVbct2SzIz35K/s0R6OEU00knqjXZq9QBKUEPTNAIBmfoDzYz1Rzg9MoBQLBhUKg7Wow0cq0TFVbddZdO4x/xXQyzP/sjgQILeeJxkrJuAX8Tr9eHz+fB4Hr9BJ9IoEe9sQLhvGOTzeRfjMYbhYpomd//6k5s3l/hj9RZfnz/P+Pg4U1PTzMzM/J+Pppk6PcnZs2f4B+SYUmXUiecpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/e4706/IFTTT-EN_03.avif 230w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/d1af7/IFTTT-EN_03.avif 460w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/7f308/IFTTT-EN_03.avif 920w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/e1c99/IFTTT-EN_03.avif 1380w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/2babf/IFTTT-EN_03.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/a0b58/IFTTT-EN_03.webp 230w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/bc10c/IFTTT-EN_03.webp 460w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/966d8/IFTTT-EN_03.webp 920w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/445df/IFTTT-EN_03.webp 1380w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/fad48/IFTTT-EN_03.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/81c8e/IFTTT-EN_03.png 230w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/08a84/IFTTT-EN_03.png 460w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/c0255/IFTTT-EN_03.png 920w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/b1001/IFTTT-EN_03.png 1380w", "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/29007/IFTTT-EN_03.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a206bc8ea6e365a4ef22ff713ce8ea99/c0255/IFTTT-EN_03.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=preset&-act=goto&-number=0&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=preset&-act=goto&-number=1&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <GoToPreset mdxType="GoToPreset" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "4-verwenden-sie-für-jede-position-unterschiedliche-erkennungsbereiche",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#4-verwenden-sie-f%C3%BCr-jede-position-unterschiedliche-erkennungsbereiche",
        "aria-label": "4 verwenden sie für jede position unterschiedliche erkennungsbereiche permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Verwenden Sie für jede Position unterschiedliche Erkennungsbereiche`}</h3>
    <p>{`In Kombination mit der geplanten Tag- und Nachtposition (siehe oben) können Sie auch verschiedene Bewegungserkennungsbereiche aktivieren - im Beispiel werden die Bereiche 1 & 2 tagsüber und die Bereiche 3 & 4 nachts aktiviert:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f8fd9790f73786350a1a61e2706912a/29007/IFTTT-EN_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADEElEQVQoz1WP20/TBwCFf//Aspcle9nLzIYEe/3RUmhLKRVq6SgF0ToU2lqKRTow2uGUEi+MBILAtmxZYpZMky3LliyhS5agziuk5RIpiNgyqEKCo6brFipLFUS/ZWQP2/dy3s53jtDfbmLsu7cJNu/lQmeAvo5Wej7y03vqAzp8dXir9Jxp83Gu7T0+Of465fky/PX7OeY5hM2owyBKMOZLMapk6GS5CKdcZZz3yGmtNXCywUqn7wAn6y0EHEZOOytoP2hi8HQz7e4qfJY3sMjfxWFQUV2sxlokYtXIMOfvYo9aSqk8B2Fxdop49C6LM+P8Gg3zIDpJdGKUe9EId64M8cvQt4Rv/MwRVwOKnbmUqJUod+7gi8F+jrU0k/PODsqKtZiLi6ipKEfgP7wCss82SD/N8hJ4/OR3Hj5aZnnlMW6PB6lcjkIpUlVdQ2Y9y+3RCDKNFvveWhwH3qfB5UbIZrNsbGywubnJ2lqG8Ngk8fg8vNzi1dbWtuhZJoPb5UQmkyEqlVRW2hgNT3Dl6nX0Wi0KhQK5UkmhWv3/hf+QXF1lPZ2Gf8uWpqa4881lvE1epBIJhRoNBQUFnD1zFr+/BYVMgkolIubl4thdgjAyMU1kLsH4TJyJ6Rh3ZxeYvhfn+YsXJMbHuXbxM/7I/IbH24REIqWwSItarUatKyVfZ0JTYkZXasFqsuA/HkAIfT/E9WAXP/0QYuz+PDPxJULDN1lcTLByf4bUepK1539ypNGNUZWDWa9EFEXqnE1U73Oz/6CXssp6nL4PuTUWRZgfDvFAl0ePowvn4Ulis2lSqRVisfj25XTkMumVOToCPpx1tficNehVIga7npzzb5LX+BatR1/j4y4DyWQKITL0JYkTHj5v+wqXM8KNW6MkVx8Sjy3w19M0kxdbiYdDtLV4afR48B4+hLgrD8+Jehqu1RL48ShXh/dx83Yny0urCBXmcuw1dmx2G/bqSqy2PZSZdjMyMkI69YS5uVkeJRb4+tIlgsEg3d3d23muM8hAzwCDfYP09g7Q3/8pF3r7+BsWFT2NoUVD/AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f8fd9790f73786350a1a61e2706912a/e4706/IFTTT-EN_04.avif 230w", "/en/static/2f8fd9790f73786350a1a61e2706912a/d1af7/IFTTT-EN_04.avif 460w", "/en/static/2f8fd9790f73786350a1a61e2706912a/7f308/IFTTT-EN_04.avif 920w", "/en/static/2f8fd9790f73786350a1a61e2706912a/e1c99/IFTTT-EN_04.avif 1380w", "/en/static/2f8fd9790f73786350a1a61e2706912a/2babf/IFTTT-EN_04.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f8fd9790f73786350a1a61e2706912a/a0b58/IFTTT-EN_04.webp 230w", "/en/static/2f8fd9790f73786350a1a61e2706912a/bc10c/IFTTT-EN_04.webp 460w", "/en/static/2f8fd9790f73786350a1a61e2706912a/966d8/IFTTT-EN_04.webp 920w", "/en/static/2f8fd9790f73786350a1a61e2706912a/445df/IFTTT-EN_04.webp 1380w", "/en/static/2f8fd9790f73786350a1a61e2706912a/fad48/IFTTT-EN_04.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f8fd9790f73786350a1a61e2706912a/81c8e/IFTTT-EN_04.png 230w", "/en/static/2f8fd9790f73786350a1a61e2706912a/08a84/IFTTT-EN_04.png 460w", "/en/static/2f8fd9790f73786350a1a61e2706912a/c0255/IFTTT-EN_04.png 920w", "/en/static/2f8fd9790f73786350a1a61e2706912a/b1001/IFTTT-EN_04.png 1380w", "/en/static/2f8fd9790f73786350a1a61e2706912a/29007/IFTTT-EN_04.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f8fd9790f73786350a1a61e2706912a/c0255/IFTTT-EN_04.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setmdattr&-name=1&-enable=1&cmd=setmdattr&-name=2&-enable=1&cmd=setmdattr&-name=3&-enable=0&cmd=setmdattr&-name=4&-enable=0&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setmdattr&-name=1&-enable=0&cmd=setmdattr&-name=2&-enable=0& cmd=setmdattr&-name=3&-enable=1&cmd=setmdattr&-name=4&-enable=1&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetMdAttr mdxType="SetMdAttr" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "5-verschiedene-empfindlichkeiten-für-tag--nacht-verwenden",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#5-verschiedene-empfindlichkeiten-f%C3%BCr-tag--nacht-verwenden",
        "aria-label": "5 verschiedene empfindlichkeiten für tag  nacht verwenden permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Verschiedene Empfindlichkeiten für Tag & Nacht verwenden`}</h3>
    <p>{`Da für die Bewegungserkennung bei Tag und Nacht oft unterschiedliche Empfindlichkeiten erforderlich sind, können Sie diese auch anpassen - im Beispiel auf 75% am Tag und 25% in der Nacht:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d92c3ce2addf5228c785e30f41b5369d/29007/IFTTT-EN_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADBUlEQVQozzWP609bBRjGzz/hR50uZAFGa1GkayrQZVOUfhhzOudQxmkrMJQFNxMvk00qzAW8RJyddQl1MowZjGRx6wTcXGUtN8O8LbAiyMJY1AO9s56eS3+GTp/kyfs8X355XqHo4ginRp/ngaFbWIIpyoIxrME4xkCMx4MxCgMxNo9E2f9OA13HDFh3O2k47Kb53WO80voeLe7j1B58E7tjP4ann0HYOrrEvlAIWyjGsz8lsE/E2T4Wz8GeHI9TEIhRFYrxUnMTz9WUUmrfRVWNyBM1TrbVNlC2R6TiBQeVYiOmyh0IiqKR1kHXde6qOosJmeVIHCmVZiWtIckaCVnBITrZlG9kyxYzG/PyeN39PlO/z/DD2GTuXp2+wd6XGxEymQxZTUXXNDKazl/JNPJainUlZZhaTBFXVFxOBwajAavVyqb8Arp7TvO/tCzciuq4Wg4haLqOBujZLGsZjTuJu6hymoycZmTkMrejMhH5HrCwIB9z6aM8+NAGTn51hvDsDOGbs/9BszgOHEQYuhzA5xvgbL+fc9+P8uvyP8TTMotSjO8mZogqGqtpBZdDxFBcSlmFnc0mK8e9vVz9ZYHxG/PcllYIL//Ni42vIvw4PsmdTz4k9NohBv1z3Pwzw9JKlLGff0MHYrJKRlFxiCL26l3sbnGyp8lJ1c6nOHXGxzn/MMUWK+WVVdi2bUcYDlxiun4nvSUVlJjOc/gNP8nEElMTU7lXspqGqiq4RBdGcyF5nvt47K37aXtbYGj4CH19g+Rt3EDJI8VUlJcjDPT30v/ZB5z1ePB6L3L662+48O0gwWuhHFBVVRR1faGD5gMNeIa6+fLCF1zyf8T09St4Pj2J8WEjZrMZm82GsDC/wOT0da5NjDPQ30c4PMsfc/Mkk8l7C7PZHLSuro7qHdWIe0VOfHyCrq5u6uub2Fdbi8lkoqioCIvFghCJRFhLpViRJObm5lnviWSC1dVVJEnKeT37fD5aW1tpc7fR4+vB6/2co0eP4Ha76ejooL29nc7OTv4FZE6BbIDa1rkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d92c3ce2addf5228c785e30f41b5369d/e4706/IFTTT-EN_05.avif 230w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/d1af7/IFTTT-EN_05.avif 460w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/7f308/IFTTT-EN_05.avif 920w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/e1c99/IFTTT-EN_05.avif 1380w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/2babf/IFTTT-EN_05.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d92c3ce2addf5228c785e30f41b5369d/a0b58/IFTTT-EN_05.webp 230w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/bc10c/IFTTT-EN_05.webp 460w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/966d8/IFTTT-EN_05.webp 920w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/445df/IFTTT-EN_05.webp 1380w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/fad48/IFTTT-EN_05.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d92c3ce2addf5228c785e30f41b5369d/81c8e/IFTTT-EN_05.png 230w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/08a84/IFTTT-EN_05.png 460w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/c0255/IFTTT-EN_05.png 920w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/b1001/IFTTT-EN_05.png 1380w", "/en/static/d92c3ce2addf5228c785e30f41b5369d/29007/IFTTT-EN_05.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d92c3ce2addf5228c785e30f41b5369d/c0255/IFTTT-EN_05.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setmdattr&-name=1&-s=75&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setmdattr&-name=1&-s=25&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetMdAttr2 mdxType="SetMdAttr2" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "6-schalten-sie-alle-kameras-aus-wenn-sie-nach-hause-kommen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#6-schalten-sie-alle-kameras-aus-wenn-sie-nach-hause-kommen",
        "aria-label": "6 schalten sie alle kameras aus wenn sie nach hause kommen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Schalten Sie alle Kameras aus, wenn Sie nach Hause kommen.`}</h3>
    <p>{`Deaktivieren Sie alle Alarmerkennungsbereiche und den PIR-Sensor manuell, wenn Sie keinen Alarm auslösen möchten:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e5d932aff76aa6c41688cde2c2926b8d/29007/IFTTT-EN_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQ0lEQVQoz3WSy27TQBSG/Qx9CZAQiNIFYoGEWLNsaaBCiLcpvASwYYEQFSAkLpWaXqANrXuBKgXaNGlELs3F9tixY8+M/SFPUqkSYqTj8Tn+ff7/PzOW9abHpaLLtRWPyRWPqRWPC0suN9byPODi0iFTKxtcXT7hStHnctFlctnj+qrHxIc+Ex8drHc9rIUu1kIP6/a64K7tM2cLCls+c9s+M1sBs7ZPwQ6Z3qrxYMdmdqfJ9PaQe/l3O6CwE3J/N+TRbsCdkuDmF8GtrwKL8UqAGFD8ZyUKKRya9RpHB2UGTg8dDf6BWTKDmoh4+eo1z589xf5VIQKyLEMqTa/f56R+wp9Gg9VNm+LiJ/bsTdZLJRqtNrWTOu3TDmEco/OG+eOg3uBhYYbH8/MG5GjQMqFWq9HpdEikNOybP494/3mRUmmD7f2yqSmtcV2XarVKEARYKdAKE/bsDVq13/RlRqhSyFKE758zk+FpCIEAcFNjYxSA7/tIKbHygsrAS6EVSUSiDSBNUzwhxq2gMUw5HGgOfMW+kBwGytSyMZ0QAqXU6FAcf8i3/Sb1UCPUCKLTlMFgQL1e5+i4SqDBHUoGUhOozOTd3NmPfcIwNHZHCoFS+ZQnL7aJVYoeU+YKc2Cj0aTdbo9IZEKmNXo801RJKpVjkiQx5Ebh96pgvdzh7VqFOFFmdrm6vGG32zXhOA6e5xlbed5stszMHNel3++bQ8nreWMrihXhMCGKpbkm6bhZHnEcG5VRFJn9LM7nZ+/5nv/zF3IhIc0l1w37AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5d932aff76aa6c41688cde2c2926b8d/e4706/IFTTT-EN_06.avif 230w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/d1af7/IFTTT-EN_06.avif 460w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/7f308/IFTTT-EN_06.avif 920w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/e1c99/IFTTT-EN_06.avif 1380w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/2babf/IFTTT-EN_06.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e5d932aff76aa6c41688cde2c2926b8d/a0b58/IFTTT-EN_06.webp 230w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/bc10c/IFTTT-EN_06.webp 460w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/966d8/IFTTT-EN_06.webp 920w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/445df/IFTTT-EN_06.webp 1380w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/fad48/IFTTT-EN_06.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e5d932aff76aa6c41688cde2c2926b8d/81c8e/IFTTT-EN_06.png 230w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/08a84/IFTTT-EN_06.png 460w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/c0255/IFTTT-EN_06.png 920w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/b1001/IFTTT-EN_06.png 1380w", "/en/static/e5d932aff76aa6c41688cde2c2926b8d/29007/IFTTT-EN_06.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e5d932aff76aa6c41688cde2c2926b8d/c0255/IFTTT-EN_06.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setmdattr&-name=1&-enable=0&cmd=setmdattr&-name=2&-enable=0&cmd=setmdattr&-name=3&-enable=0&cmd=setmdattr&-name=4&-enable=0?cmd=setpirattr&-pir_enable=0&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setmdattr&-name=1&-enable=1&cmd=setmdattr&-name=2&-enable=1&cmd=setmdattr&-name=3&-enable=1&cmd=setmdattr&-name=4&-enable=1?cmd=setpirattr&-pir_enable=1&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetMdAttr3 mdxType="SetMdAttr3" />
    <p>{`Bitte beachten Sie bei den Modellen `}<strong parentName="p">{`IN-6014 HD`}</strong>{` und `}<strong parentName="p">{`IN-9020 Full HD`}</strong>{`, anstelle des PIR-Sensors - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setpirattr&-pir_enable=1`}</code>{` - der Alarmeingang geschaltet werden muss: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cmd=setioattr&-io_enable=1`}</code>{`. Dasselbe gilt für Kameras, bei denen ein externer PIR-Sensor am Alarmeingang angeschlossen ist.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "7-verwenden-sie-externe-sensoren-um-ihre-kamera-zu-drehen-wenn-bewegung-erkannt-wird",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#7-verwenden-sie-externe-sensoren-um-ihre-kamera-zu-drehen-wenn-bewegung-erkannt-wird",
        "aria-label": "7 verwenden sie externe sensoren um ihre kamera zu drehen wenn bewegung erkannt wird permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`7. Verwenden Sie externe Sensoren, um Ihre Kamera zu drehen, wenn Bewegung erkannt wird.`}</h3>
    <p>{`Lassen Sie Ihre Kamera je nach Systemvariablen im Heimautomationssystem zwischen Positionen schwenken. Wenn beispielsweise der Türsensor ausgelöst wird, schauen zur Tür, wenn der Fenstersensor ausgelöst wird, schwenke in Richtung Fenster und wenn der externe Bewegungssensor eine Person erkennt, wenden dich zur Küchentür:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/29007/IFTTT-EN_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACk0lEQVQozz2OO48bZRSGp+En0CEqBAEKKBBdkFAuggKJhg7xB5DC/0CpaIGCCqWBgBIiLYrtZFl71/ay6+sa78WxnfHMfPPNN/f7jh9kW8qR3uI8el6do72+p3iv4fJOzeXDZx7v1t1t3q67fLTv8cbfijefuny87/FWbcc/eOZxo+byft3lRn3Xfe2xg/ZQon3a8rlzFHK7HXH7KORuJ+azTsyG32r53D0K+OYk5OuTkE+aO7blnZg7nYRbRxFfdCM+bwfcPPDQWF9T+C7G7ALylMg2SGyDV7O+hqqEstgsr3DiCCJziW+tSDwFVLAu0XRD8lKFzL2YoR3y72jC6GLGVVzhlWBJRZQkmEKh2z7BGq7iNcfjKf2z/xiKgKUXIz0f5floUfuQolEDNl+k2yubrMuUqkzJ05Aiiwl9RRIoqjyiKuKdt+lUOVQFZRaTJSFa1vgTfrhP2WuSyzHOqk9s9slFn0z0KcQpqT0hU3MKZ0buvKCwx/hGH98cbL1C9HaeNUQrOg3CP37DO2iAGKMbQwKzRyEn5NMHlAffkeqHZIFNGTiUsce1miJWPRxzSCWHyKsWyfH3pPOnaOnkANH9C2tUo7R6nE+PkYsTCveSfPQj13tfkumHROaE+EWLaN6mME95OTtlft6FcEr90S9Mf/2KtV5Di0aPyU5/pzx7hLh4TrPTZXA2IJ41yQc/ke9/S6K3iKfPSbsPSAcPCRZNhmdj/mm3yRZNuk9+ZvnkHsV8Dy2V52Tqilydk4QLVkohfEURzimiJXmqk8YGqZqRq0tydUEazjGUjeV7lNGCwLkkT5ak0RLNcixsJRBKYDsm0taRcoVwrF2kiXQE0pUIZW29DZf2Cil1xKaz7VpYtsH/D4MNRTpMpNsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/e4706/IFTTT-EN_07.avif 230w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/d1af7/IFTTT-EN_07.avif 460w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/7f308/IFTTT-EN_07.avif 920w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/e1c99/IFTTT-EN_07.avif 1380w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/2babf/IFTTT-EN_07.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/a0b58/IFTTT-EN_07.webp 230w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/bc10c/IFTTT-EN_07.webp 460w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/966d8/IFTTT-EN_07.webp 920w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/445df/IFTTT-EN_07.webp 1380w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/fad48/IFTTT-EN_07.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/81c8e/IFTTT-EN_07.png 230w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/08a84/IFTTT-EN_07.png 460w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/c0255/IFTTT-EN_07.png 920w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/b1001/IFTTT-EN_07.png 1380w", "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/29007/IFTTT-EN_07.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/68a4fdddbb4e1e8fb74d03e1c82d4b21/c0255/IFTTT-EN_07.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=preset&-act=goto&-number=0&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=preset&-act=goto&-number=1&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=preset&-act=goto&-number=2&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <GoToPreset2 mdxType="GoToPreset2" />
    <p>{`etc.`}</p>
    <p>{`Dieses lässt sich gut mit der Parkposition in der Kamera kombinieren - die Kamera kehrt dann nach x Minuten automatisch in die Normalposition zurück.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "8-verwenden-sie-externe-sensoren-um-alarmaufzeichnungen-auszulösen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#8-verwenden-sie-externe-sensoren-um-alarmaufzeichnungen-auszul%C3%B6sen",
        "aria-label": "8 verwenden sie externe sensoren um alarmaufzeichnungen auszulösen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8. Verwenden Sie externe Sensoren, um Alarmaufzeichnungen auszulösen.`}</h3>
    <p>{`Wenn Sie Ihre Kamera so einrichten, dass sie sich bei Auslösung durch einen externen Sensor in eine Position dreht, möchten Sie wahrscheinlich eine Alarmaufnahme auf der internen SD-Karte starten, um den Eindringling zu erfassen. Dies kann geschehen, indem Sie die manuelle Aufzeichnung über Ihr Hausautomationssystem starten und nach einer Verzögerung dem Stoppbefehl senden. Durch die einstellbare Verzögerung ist die Länge des Alarmvideos frei wählbar:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7efd5446b6f2df176a75aaf829b94a56/29007/IFTTT-EN_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACFUlEQVQoz1WP2W7TQBSG/XK8DO+BEBJ3fQLEXS8qAVdVi1S6qSlkT5Ad26FNYseOs4+X8RJ/yJOoaUf6dLb5/zmjlWVJRXWyLCOOE5WrXlkSyRTd0KldXdJrt4iS/bw6SRLjex6+7yOEqFRoSny4EEaSiRMQlbDIYFuAHZWc3z7wUG/SMCxsUeBuIp4WW0ReIkSICOOXR7RsPSRbDcnX/0iXNpGnIwOTdG4hZwPypQ3hmF04pgzHRDODldNjOekTz22SuYlcWMqj8tKk1+RInbwabDxyEZCtHFK/TThtsHKbpF6DdNYmD1rk7h3p5EbNU//ooaWzDnu6qpFvJxTxlp0MKcIl2byP8Dp4bo981kb6LeTCpOidkNU/IOfG3vTg82IoDzEa1dhat4jhPVvzmsxrsPE6mOM+RdCGjcGf62/oZ++h+4kk0N8aSq/Fniay2mB0hzQvkYOLfe41eTbq3N/d4A7uIWjy8/sXzj+/g95HYr9/+G5Lba/F7gMVUYX/h6hzRvR4Slg7JemcsXR+07UNrroW3b+PMLqhfvEV/dcJu/EPQvdxr1XU0JJCkBQh2U4ociIyEoXKd4Llas5qvSZOtpQkeMFIaQrSo67cR82JY4ZhguOaOFOb0cTAndo4rsXzWFfRcY/12BkwC55xp5bKJ0pn8TTSWa4m1YYpcZ4i05A0i97EikQKVb/uveZlJgVZHvMfzbgyDHRIsQoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7efd5446b6f2df176a75aaf829b94a56/e4706/IFTTT-EN_08.avif 230w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/d1af7/IFTTT-EN_08.avif 460w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/7f308/IFTTT-EN_08.avif 920w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/e1c99/IFTTT-EN_08.avif 1380w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/2babf/IFTTT-EN_08.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7efd5446b6f2df176a75aaf829b94a56/a0b58/IFTTT-EN_08.webp 230w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/bc10c/IFTTT-EN_08.webp 460w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/966d8/IFTTT-EN_08.webp 920w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/445df/IFTTT-EN_08.webp 1380w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/fad48/IFTTT-EN_08.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7efd5446b6f2df176a75aaf829b94a56/81c8e/IFTTT-EN_08.png 230w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/08a84/IFTTT-EN_08.png 460w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/c0255/IFTTT-EN_08.png 920w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/b1001/IFTTT-EN_08.png 1380w", "/en/static/7efd5446b6f2df176a75aaf829b94a56/29007/IFTTT-EN_08.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7efd5446b6f2df176a75aaf829b94a56/c0255/IFTTT-EN_08.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=manualrec&-act=on&-time=60&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=manualrec&-act=off&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <ManualRec mdxType="ManualRec" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "9-verbinden-sie-ihre-kameras-miteinander",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#9-verbinden-sie-ihre-kameras-miteinander",
        "aria-label": "9 verbinden sie ihre kameras miteinander permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`9. Verbinden Sie Ihre Kameras miteinander`}</h3>
    <p>{`Installieren Sie Ihre Kameras so, dass sie einander immer im Auge behalten, indem Sie die `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{` verwenden, um Ihre Kamera nach dem Schwenk immer in diese  Ruheposition bringt. Auf diese Weise wird verhindert, dass Eindringlinge Ihre Kameras unbemerkt manipulieren.`}</p>
    <p>{`Wenn eine Kamera eine Bewegung erkennt, können Sie sie über die `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarmserver-Funktion`}</a>{` dieser Kamera Ihr Hausautomationssystem alarmieren. Das Smarthome kann dann einen Befehl an die andere Kamera in der Nähe senden, damit diese sich in Richtung des Eindringlings dreht und eine Alarmaufzeichnung starten.`}</p>
    <p><img parentName="p" {...{
        "src": "./IFTTT-EN_11.png",
        "alt": "IFTTT"
      }}></img></p>
    {/* ```bash
     /param.cgi?cmd=preset&-act=goto&-number=1&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=preset&-act=goto&-number=2&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=manualrec&-act=on&-time=60&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?/cmd=manualrec&-act=off&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <GoToPreset3 mdxType="GoToPreset3" />
    <p>{`In diesem Beispiel schauen sich beide Kameras an - und behalten im Auge, was hinter der anderen Kamera passiert. Diese Position wird als `}<strong parentName="p">{`Position 1`}</strong>{` (`}<em parentName="p">{`number=0`}</em>{`) gespeichert und als `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{` eingestellt. Wenn die linke Kamera etwas erkennt, dreht sich die rechte zum potenziellen Eindringling um - gespeichert als `}<strong parentName="p">{`Position 2-8`}</strong>{` (`}<em parentName="p">{`number=1-7`}</em>{`) - und startet eine Aufzeichnung. Und umgekehrt.....`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "10-nach-geräuschquellen-suchen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#10-nach-ger%C3%A4uschquellen-suchen",
        "aria-label": "10 nach geräuschquellen suchen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`10. Nach Geräuschquellen suchen`}</h3>
    <p>{`Wenn Sie die Audioerkennung auf Ihrer Kamera auslöst, kennen Sie wahrscheinlich nicht die Position der Geräuschquellen. Anstatt eine bestimmte Position anzufahren, können Sie hierdann einen horizontalen (hscan) oder vertikalen (vscan) Suchlauf auslösen. Dieser muss dann mit der gewünschten Verzögerung wieder manuell gestoppt werden:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8023930c739b028a7c964a2a5cb645d2/29007/IFTTT-EN_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQ0lEQVQoz4WPS08TcRTF56u58iPoR3DvwoWJO2OiKxcKa6OJLowsCCihoEWgUAb64tXptNDOTDsttLSdx//RmZ/p+IgmGm/yy805ufckx0jTlL9BmpIkCVEQ0Pd9Wq0m49EIKQTzSdOENPnBb38G/5mS1WJzI0fVLFKq1L6b6b/vDXXTRI2aqJsW8rqB6NcRVw3EoI4eNjg6zJNbe09x5yOHxRx6ZKMiB9Uqo4/30c0y2qmhhnaWYYjeERldE3VdR4176OkANXIR/Rphu4DXKdF0j2FUQ3om4jyPLK8gl58jlxeIK+v8zDFkv4LsVzOhx5fMogmJCJiFI+SwjlNdx6xsc1LfxzZXmXSPULkP6O1FWLpDsv2YNLSRfpl5VhYo/ApyUCVq7zGxtpg2d5g0tog8k9g1Mau7nJwVuWkVmLomurhGuPmC5sJtqm/vUdpdZXZVQ/jleeUSc6RfymoLK4e01hH2BtI/InAPuWickv+Sp9vYY9bdR7gl5NdFjhdu8e7pXR4+uE/gHKD8EkbkFYi9AmGvSNjcINx9Q2guERReI5wtOp0yZstls2ZTrZdRVo7YKRCdrsDZKw4+veTZk0dc2Z8RfhEjnk2J9BSdTNFpgCL+xVwLNaHf9xmPx0RigiZAJVNkGpKQ4nQvsOwag6FLQojhxxFeLOj2bC4uT/H7LTpOnY5zjuNatJ06/qBNx7Uy5p7rNXA8C8+zcLsNpB6hk0m2jXgmiWYKKQPCaIzSEUIGfyBVmPE3PxZTtI6YJSLjGxYzKs/Ckuo2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8023930c739b028a7c964a2a5cb645d2/e4706/IFTTT-EN_09.avif 230w", "/en/static/8023930c739b028a7c964a2a5cb645d2/d1af7/IFTTT-EN_09.avif 460w", "/en/static/8023930c739b028a7c964a2a5cb645d2/7f308/IFTTT-EN_09.avif 920w", "/en/static/8023930c739b028a7c964a2a5cb645d2/e1c99/IFTTT-EN_09.avif 1380w", "/en/static/8023930c739b028a7c964a2a5cb645d2/2babf/IFTTT-EN_09.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8023930c739b028a7c964a2a5cb645d2/a0b58/IFTTT-EN_09.webp 230w", "/en/static/8023930c739b028a7c964a2a5cb645d2/bc10c/IFTTT-EN_09.webp 460w", "/en/static/8023930c739b028a7c964a2a5cb645d2/966d8/IFTTT-EN_09.webp 920w", "/en/static/8023930c739b028a7c964a2a5cb645d2/445df/IFTTT-EN_09.webp 1380w", "/en/static/8023930c739b028a7c964a2a5cb645d2/fad48/IFTTT-EN_09.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8023930c739b028a7c964a2a5cb645d2/81c8e/IFTTT-EN_09.png 230w", "/en/static/8023930c739b028a7c964a2a5cb645d2/08a84/IFTTT-EN_09.png 460w", "/en/static/8023930c739b028a7c964a2a5cb645d2/c0255/IFTTT-EN_09.png 920w", "/en/static/8023930c739b028a7c964a2a5cb645d2/b1001/IFTTT-EN_09.png 1380w", "/en/static/8023930c739b028a7c964a2a5cb645d2/29007/IFTTT-EN_09.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8023930c739b028a7c964a2a5cb645d2/c0255/IFTTT-EN_09.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=ptzctrl&-step=0&-act=hscan&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=ptzctrl&-step=0&-act=vscan&-usr={USERNAME}&-pwd={PASSWORD}
     /ptzctrl.cgi?-step=&-act=stop
     ``` */}
    <PtzCtrl mdxType="PtzCtrl" />
    <p>{`Alternativ können Sie mit der Tourfunktion zwischen `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`in der WebUI vordefinierten`}</a>{` Positionen bis zu maximal 50 Mal schwenken:`}</p>
    {/* ```bash
     /param.cgi?cmd=ptzctrl&-step=0&-act=tour&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <PtzCtrlTour mdxType="PtzCtrlTour" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "11-manually-disable-all-alarm-functions-for-all-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#11-manually-disable-all-alarm-functions-for-all-your-cameras",
        "aria-label": "11 manually disable all alarm functions for all your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`11. Manually disable all alarm functions for all your cameras.`}</h3>
    <p>{`Wenn Sie mehr als einen Alarmauslöser in Ihrer Kamera aktiviert haben - PIR-Sensor, Alarmbereiche, Audioerkennung, Alarmeingang -, können Sie über den Alarmplan alle auf einmal aktivieren und deaktivieren, ohne jeden Auslöser einzeln ansprechen zu müssen:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a48629bea720602707ce6aa056de6ed3/29007/IFTTT-EN_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACW0lEQVQoz2WRy2oUURCGeycuRAT1CRS87twYn0AQTKL4BOJCBFfiO6mgqxhDkpnEhFzGyQ2imTEzk2Sm79fTPd2d6dsn3ZOgYMFP1anzFedUlXR9zuFe3eXmosutmsvtmsuVWZtLX20uz9pcnLG5NieYWDnmTm2XG4s69+uCu/Uxe1539ZvDhRkb6fG6YLoheNYQPG34PNn0mVgVPFoLKv/gu8eL5pCXOzKTm/tMN0ymGgFTDZ/JHwHTzZDnzYCHK14lCXL+syKFeFgGf8/F/xhpQhIGkI7OEhnSwI9Z3GiyuvcTP4MkSVB1g6N+H9N2SLKcNMvQwxHLW7vUGtvYo5yiKJAVleOTPqqmk6QZSZYhbXdOePf6FfMLC1hJwWkUosgynXYLQ9eJoog0L+haHu/fvuHzp48VV+YNXaPTbqOpCmEYkqYpkuJHtLpd9CBCc316J33qy0usLy2wsb6G67oIz8OOUw66PVQRoLh+1cV8rc5aya2u4DhOJanIc2IgymEY+AyjiN3OMTtHMkemC0WO7Ticc6cFBL5gNErY65bcgEPNgjzHcVwkJ06IR2k10hI0gwgjGW9gEOf4UYzrOFAUZGnGaQ5C+IgwQj4dc3Kc44URnusg9a0hH+od0qyoWtv/dVD9sjTdtOj0eniuWy3hy3KHlh4SDANa7Tau8CvO9gS/Dw+r8UijUUptS6arx3ieh6IoCCEq2ZaFpmn4wqOnhyztKARRShQGY87z8ITAsW1UVR3P0DBNTMvCNC10XWcwGDCQ5bE/k1XeW/aZtzAMo2Lkf7gyLh//A2o1F3DmKpywAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a48629bea720602707ce6aa056de6ed3/e4706/IFTTT-EN_10.avif 230w", "/en/static/a48629bea720602707ce6aa056de6ed3/d1af7/IFTTT-EN_10.avif 460w", "/en/static/a48629bea720602707ce6aa056de6ed3/7f308/IFTTT-EN_10.avif 920w", "/en/static/a48629bea720602707ce6aa056de6ed3/e1c99/IFTTT-EN_10.avif 1380w", "/en/static/a48629bea720602707ce6aa056de6ed3/2babf/IFTTT-EN_10.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a48629bea720602707ce6aa056de6ed3/a0b58/IFTTT-EN_10.webp 230w", "/en/static/a48629bea720602707ce6aa056de6ed3/bc10c/IFTTT-EN_10.webp 460w", "/en/static/a48629bea720602707ce6aa056de6ed3/966d8/IFTTT-EN_10.webp 920w", "/en/static/a48629bea720602707ce6aa056de6ed3/445df/IFTTT-EN_10.webp 1380w", "/en/static/a48629bea720602707ce6aa056de6ed3/fad48/IFTTT-EN_10.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a48629bea720602707ce6aa056de6ed3/81c8e/IFTTT-EN_10.png 230w", "/en/static/a48629bea720602707ce6aa056de6ed3/08a84/IFTTT-EN_10.png 460w", "/en/static/a48629bea720602707ce6aa056de6ed3/c0255/IFTTT-EN_10.png 920w", "/en/static/a48629bea720602707ce6aa056de6ed3/b1001/IFTTT-EN_10.png 1380w", "/en/static/a48629bea720602707ce6aa056de6ed3/29007/IFTTT-EN_10.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a48629bea720602707ce6aa056de6ed3/c0255/IFTTT-EN_10.png",
              "alt": "IFTTT",
              "title": "IFTTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setscheduleex&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetSchedule mdxType="SetSchedule" />
    <p>{`In diesem Befehl steht `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`week0 - week6`}</code>{` für Montag bis Sonntag und jedes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`P`}</code>{` ist eine halbe Stunde des Tages, an dem der Alarmplan aktiv ist (`}<em parentName="p">{`48 x 30min = 24h`}</em>{`). Ein `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`N`}</code>{` entspricht einem Zeitraum von 30 Minuten, in dem es deaktiviert ist. Der Zeitplan aktiviert / deaktiviert alle Alarmaktionen.`}</p>
    <p><strong parentName="p">{`Update November 2019`}</strong>{` `}<em parentName="p">{`(nur für Full HD Kameramodelle)`}</em></p>
    <p>{`Aufgrund einer Änderung in der Web-Benutzeroberfläche wird der Alarmplan nun in einer neuen Variablen namens `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setbackupschedule`}</code>{` gespeichert. Wenn Sie den obigen Befehl mit nur `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setscheduleex`}</code>{` verwenden, wird der aktive Alarmplan geändert, aber diese Änderung ist innerhalb des WebUI nicht sichtbar. Es wird empfohlen, immer beide Variablen zu setzen, um eine De-Synchronisation zu verhindern:`}</p>
    {/* ```bash
     /param.cgi?cmd=setscheduleex&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&cmd=setbackupschedule&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetScheduleBak mdxType="SetScheduleBak" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "12-umschalten-zwischen-pir-und-software-bewegungserkennung",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#12-umschalten-zwischen-pir-und-software-bewegungserkennung",
        "aria-label": "12 umschalten zwischen pir und software bewegungserkennung permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12. Umschalten zwischen PIR und Software Bewegungserkennung`}</h3>
    <p>{`Die meisten unserer neuen Kameras verfügen sowohl über eine interne, softwarebasierte Bewegungserkennung als auch über einen Hardware-PIR (Passiv-Infrarot)-Wärmesensor, um die Bewegung von Objekten zu erfassen, die eine andere Oberflächentemperatur als die Umgebung aufweisen. In den meisten Fällen empfehlen wir Ihnen, beide `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`gekoppelt`}</a>{` zu verwenden, da sie unterschiedliche Stärken und Schwächen haben und die Kopplung viele Quellen von Fehlalarmen filtern kann.`}</p>
    <p>{`Aber es gibt Situationen, in denen eine dieser beiden Optionen, einfach fehlschlägt und keine Filterwirkung mehr bietet. Beispiele sind:`}</p>
    <ul>
      <li parentName="ul">{`Der PIR ist den größten Teil des Tages direktem Sonnenlicht ausgesetzt. Schatten und Temperaturschwankungen lösen regelmäßig Fehlalarme aus.`}</li>
      <li parentName="ul">{`Die Bewegungserkennungsbereiche werden nachts ständig durch das Licht der vorbeifahrenden Autos  ausgelöst.`}</li>
      <li parentName="ul">{`usw.`}</li>
    </ul>
    <p>{`Mit unserem Smarthome können wir den problematischen Sensor in Zeiten, in denen wir wissen, dass seine Genauigkeit beeinträchtigt ist, automatisch deaktivieren. Da wir wahrscheinlich beide Sensoren zu Tageszeiten einsetzen wollen, in denen beide perfekt funktionieren, müssen wir auch die Kopplung entsprechend ein- und ausschalten.`}</p>
    <p><strong parentName="p">{`Link between PIR and Alarm Areas`}</strong></p>
    {/* ```bash
     /param.cgi?cmd=setmdalarm&-aname=type&-switch=on
     /param.cgi?cmd=setmdalarm&-aname=type&-switch=off
     ``` */}
    <SetMdAlarm mdxType="SetMdAlarm" />
    <p><strong parentName="p">{`Alarm Areas Activation / Deactivation`}</strong></p>
    {/* ```bash
     /param.cgi?cmd=setmdattr&-name=1&-enable=1&cmd=setmdattr&-name=2&-enable=1&cmd=setmdattr&-name=3&-enable=1&cmd=setmdattr&-name=4&-enable=1
     /param.cgi?cmd=setmdattr&-name=1&-enable=0&cmd=setmdattr&-name=2&-enable=0&cmd=setmdattr&-name=3&-enable=0&cmd=setmdattr&-name=4&-enable=0
     ``` */}
    <SetMdAttr4 mdxType="SetMdAttr4" />
    <p><strong parentName="p">{`PIR Activation / Deactivation`}</strong></p>
    {/* ```bash
     /param.cgi?cmd=setpirattr&-pir_enable=1
     /param.cgi?cmd=setpirattr&-pir_enable=0
     ``` */}
    <SetPirAttr mdxType="SetPirAttr" />
    <p>{`In case of the IN-6014 HD or IN-9020/10 Full HD (also camera models with attached external PIR sensors) you have to switch the alarm input instead:`}</p>
    {/* ```bash
     /param.cgi?cmd=setioattr&-io_enable=1
     /param.cgi?cmd=setioattr&-io_enable=0
     ``` */}
    <SetIoAttr mdxType="SetIoAttr" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      